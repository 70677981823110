const BranchManagerInitialState = {
	firstname: "",
	lastname: "",
	email: "",
	phonenumber: "",
	username: "",
	password: "",
	isDisabled: false
};

export default BranchManagerInitialState;