const branchInitialState = {
	name: "",
	addressline: "",
	street: "",
	city: "",
	state: "",
	country: "",
	pincode: "",
	landmark: "",
	email: "",
	phone: ""
};

export default branchInitialState;