const admissionFormInitialState = {
	firstname: "",
	lastname: "",
	middlename: "",
	photoURL: "",
	gender: "male",
	DOB: "",
	address: "",
	landmark: "",
	city: "",
	state: "",
	pincode: "",
	nationality: "",
	email: "",
	mobileNoPrimary: "",
	mobileNoSecondary: "",
	admissionYear: "",
	grade: "",
	father_name: "",
	father_phone_number: "",
	father_email: "",
	father_occupation: "",
	mother_name: "",
	mother_phone_number: "",
	mother_email: "",
	mother_occupation: "",
	guardian_name: "",
	guardian_phone_number: "",
	guardian_email: "",
	guardian_occupation: "",
	password: "",
	username: "",
	confirmed: false,
	isDisabled: false,
	mode: "regular",
	scores: "0",
	institutename: "",
	requirecoachingfor: ""
}

export default admissionFormInitialState